<template>
  <footer>
    <div class="container">
      <div class="row">
        <v-col cols="12" md="4" class="d-none d-md-block">
          <div class="copyrights">
            <p class="mb-0">{{ siteData.copy_rights }}</p>
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <ul class="footer_links d-flex justify-content-start align-items-start flex-row flex-wrap pl-0">
            <li v-if="GetUserData">
              <a href="javascript:void(0);" @click.prevent="handleDashboardRedirect">
                {{ $t("footer.joinSin") }}
              </a>
            </li>
            <li>
              <router-link to="/about">{{ $t("footer.about") }}</router-link>
            </li>
            <li>
              <router-link to="/contact-us">{{ $t("footer.contact") }}</router-link>
            </li>
            <li>
              <router-link to="/refund-policy">{{ $t("footer.refund-policy") }}</router-link>
            </li>
            <li>
              <router-link to="/faqs"> {{ $t("footer.faq") }}</router-link>
            </li>
            <li>
              <router-link to="/terms"> {{ $t("footer.policy") }}</router-link>
            </li>
            <li>
              <router-link to="/agreement">{{ $t("footer.agreement") }}</router-link>
            </li>
            <li>
              <router-link to="/explanations">{{ $t("explanations") }}</router-link>
            </li>
          </ul>
        </v-col>
      </div>
      <hr/>
      <div class="row align-items-center">
        <v-col cols="12" sm="6" md="6">
          <ul class="d-flex justify-content-start align-items-center flex-row ps-0 social_links mt-3 list-unstyled"
              style="gap: 15px">
            <li v-if="getSocial.facebook">
              <a :href="getSocial.facebook">
                <v-icon color="#fff">mdi-facebook</v-icon>
              </a>
            </li>
            <li v-if="getSocial.instagram">
              <a :href="getSocial.instagram">
                <v-icon> mdi-instagram</v-icon>
              </a>
            </li>
            <li v-if="getSocial.telegram">
              <a :href="getSocial.telegram">
                <img src="../../public/img/telegram.png" alt=""/>
              </a>
            </li>
            <li v-if="getSocial.youtube">
              <a :href="getSocial.youtube">
                <v-icon color="#fff">mdi-youtube</v-icon>
              </a>
            </li>
            <li v-if="getSocial.snapchat">
              <a :href="getSocial.snapchat">
                <v-icon color="#fff">mdi-snapchat</v-icon>
              </a>
            </li>
            <li v-if="getSocial.twitter">
              <a :href="getSocial.twitter">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="16" width="16" viewBox="0 0 512 512">
                  <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.-->
                  <path
                      d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/>
                </svg>
              </a>
            </li>
            <li v-if="getSocial.whatsapp">
              <a :href="'https://wa.me/' + getSocial.whatsapp">
                <v-icon color="#fff">mdi-whatsapp</v-icon>
              </a>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <div class="payment__images d-flex justify-content-end align-items-center flex-row flex-wrap mt-3"
               style="gap: 12px">
            <div v-for="item in payment" :key="item.id">
              <img class="img-fluid" :src="item.image_url" :alt="item.name"/>
            </div>
            <img class="img-fluid" style="width: 32%" src="/img/mainPage/tourism.png" alt="tourism logo">
          </div>
        </v-col>
        <v-col cols="12" md="12" class="d-block d-md-none">
          <div class="copyrights">
            <p class="mb-0 text-center">{{ siteData.copy_rights }}</p>
          </div>
        </v-col>
      </div>
    </div>

    <div class="chat-icon" @click="slideToggle">
      <div class="position-relative">
        <img src="../../public/img/whatsapp-chat.png" alt="">
        <div class="notify"></div>
      </div>
    </div>
    <Transition name="slide-down">
      <div v-if="active" class="chat-window">
        <div class="chat-logo d-flex align-items-center justify-content-start">
          <a href="javascript:void(0);" class="exit-icon" @click="slideToggle">
            <i class="mdi mdi-close"></i>
          </a>
          <div class="position-relative">
            <img :src="whatsappSettings.whatsapp_logo" :alt="whatsappSettings.whatsapp_title">
            <div class="active-avatar"></div>
          </div>
          <div style="margin-right: 36px;">
            <h5 class="mb-1">{{ whatsappSettings.whatsapp_title }}</h5>
            <h6>{{ whatsappSettings.whatsapp_sub_title }}</h6>
          </div>
        </div>
        <div class="chat-content">
          <div class="chat-message">
            <h6>{{ whatsappSettings.whatsapp_body }}</h6>
            <span>{{ whatsappSettingsTimeNow }}</span>
          </div>
        </div>
        <div class="start-chat">
          <a :href="'https://wa.me/'+whatsappSettings.whatsapp_number" target="_blank">
            <i class="mdi mdi-whatsapp ms-1"></i>{{ $t('start-chat') }}
          </a>
        </div>
      </div>
    </Transition>
  </footer>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      active: false,
      whatsappSettingsTimeNow: '00:00',
      login_url: (process.env.NODE_ENV === 'development') ? 'http://127.0.0.1:8000/provider/login' : (['seenpark.sa', 'www.seenpark.sa'].includes(window.location.hostname)) ? 'https://dashboard.seenpark.sa/provider/login' : 'https://sinback.bb4itdev.com/provider/login',
    }
  },
  computed: {
    ...mapGetters([
      "GetUserData",
    ]),
    ...mapGetters("home", [
      "getSections",
      "getServices",
      "getSiteData",
      "getSocial",
      "getPayment",
      "getWhatsappSettings",
    ]),
    sections() {
      return this.getSections;
    },
    services() {
      return this.getServices;
    },
    siteData() {
      return this.getSiteData;
    },
    social() {
      return this.getSocial;
    },
    payment() {
      return this.getPayment;
    },
    whatsappSettings() {
      return this.getWhatsappSettings;
    },
  },
  mounted() {
    this.$store.dispatch("home/fetchSiteData");
  },
  methods: {
    slideToggle() {
      this.active = !this.active;
      let today = new Date();
      this.whatsappSettingsTimeNow = today.getHours() + ":" + today.getMinutes();
    },
    handleDashboardRedirect() {
      return axios.post("redirect-to-dashboard").then(res => {
        window.location.href = this.login_url + '?auth_token=' + res.data.data;
      }).catch(() => {
        window.location.href = this.login_url;
      })
    },
  }
};
</script>
<style scoped lang="scss">
.custom_links {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  flex-wrap: wrap;
}

.payment__images,
.footer_links {
  @media (max-width: 767px) {
    justify-content: flex-start !important;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 0;
    li {
      width: 50%;
      margin-bottom: 25px;
    }
  }
}

.social_links, .payment__images {
  @media (max-width: 767px) {
    justify-content: center !important;
    align-items: center !important;
  }
}
</style>
<style scoped>
.slide-down-enter-active,
.slide-down-leave-active {
  transition: max-height 500ms ease;
}

.slide-down-enter-to,
.slide-down-leave {
  overflow: hidden;
  max-height: 1000px;
}

.slide-down-enter,
.slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>